import Header from '@/components/Header'
import Footer from '@/components/Footer'
import useIsMobile from '@/util/useIsMobile'
import AnnoucementBar from '@/components/AnnoucementBar'

export default function MainLayout({ children }) {
  const isMobile = useIsMobile()

  return (
    <div className="min-h-screen relative overflow-hidden flex flex-col">
      <Header isMobile={isMobile} />
      <AnnoucementBar />
        <div style={{ flex: '0 1 auto' }}>
          {children}
        </div>
      <Footer isMobile={isMobile} />
    </div>
  )
}
