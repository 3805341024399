import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'


const AnnoucementBar = () => {

  return (
    <Container>
      <p className='font-soehne-leight'>USE CODE: BLACKFRIDAY for 25% OFF</p>
    </Container>
  )
}

const Container = styled.div`
  background: #000;
  color: #fff;
  text-align: center;
  padding: 12px 0;
`

export default AnnoucementBar



